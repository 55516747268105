<template>
  <div class="contact">
    <br /><br />
    <div>
      <Navbar></Navbar>
    </div>
    <v-parallax class="bg" src="../../public/image/rocket.png" height="auto">
      <div class="container" id="team">
        <section class="header textname">
          <h1>OUR TEAM</h1>
          <p>หนองน้ำเต็มบึง/Lagoon full swamp</p>
          <hr />
        </section>

        <v-row justify="center">
          <br />
          <v-col cols="12" md="3" sm="12">
            <div class="member">
              <a
                href="https://www.facebook.com/profile.php?id=100001200256778"
                target="_blank"
              >
                <img
                  class="memberimg"
                  src="../../public/image/sky.png"
                  alt="Project Manager"
                />
              </a>
              <div class="textname">
                <h3>นฤเบศร ใจสมัคร</h3>
                <h5>Project Manager</h5>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <br />
          <v-col cols="12" md="3" sm="12">
            <div class="member">
              <a
                href="https://www.facebook.com/nattapon.tongta"
                target="_blank"
              >
                <img
                  class="memberimg"
                  src="../../public/image/kluay.png"
                  alt="Business Analyst"
                />
              </a>
              <div class="textname">
                <h3>ณัฐพนธ์ ทองทา</h3>
                <h5>Business Analyst</h5>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="3" sm="12">
            <div class="member">
              <a
                href="https://www.facebook.com/profile.php?id=100002353314345"
                target="_blank"
              >
                <img
                  class="memberimg"
                  src="../../public/image/aiem.jpg"
                  alt="Developer"
              /></a>
              <div class="textname">
                <h3>กรดล สาธิตกุล</h3>
                <h5>Developer</h5>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="3" sm="12">
            <div class="member">
              <a
                href="https://www.facebook.com/pitpagon.chinanupagon"
                target="_blank"
              >
                <img
                  class="memberimg"
                  src="../../public/image/fluke.png"
                  alt="UX/UI"
              /></a>
              <div class="textname">
                <h3>พิชญ์ภากร ชินานุปกรณ์</h3>
                <h5>UX/UI</h5>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="3" sm="12">
            <div class="member">
              <a href="https://www.facebook.com/ImZekai" target="_blank">
                <img
                  class="memberimg"
                  src="../../public/image/eartdy.jpg"
                  alt="Tester"
              /></a>
              <div class="textname">
                <h3>ธีรพงษ์ เอี่ยมอาษา</h3>
                <h5>Tester</h5>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <v-container class="con" >
          <a href="mailto:koladon52@gmail.com?Subject=Some%20subject">ddddd</a>
          <a href="koladon52@gmail.com">email me here!</a>

          <div class="contact-us">
            <div>ติดต่อเราได้ที่นี่</div>
            <v-text-field
              label="email"
              solo
              dense
            ></v-text-field>
            <v-textarea
          solo
          name="input-7-4"
          label="ข้อความ"
        ></v-textarea>

          </div>
        </v-container> -->
      </div>
      <br /><br />
    </v-parallax>

    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
const Footer = () => import("@/components/navbar/footer");
const Navbar = () => import("@/components/navbar/navbar");
export default {
  components: {
    Navbar,
    Footer,
  },
  mounted: async function mounted() {
    await this.onbeforeunload();
  },
  methods: {
    onbeforeunload() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.con{
  width: 40%;
  background-color: #F9E784;
  border-radius: 20px;
}
.contact-us{
  margin: 20px;
}
.header {
  text-align: center;
}
.header h1 {
  font-weight: bold;
}
h1{
  font-size: 32px;
}
h3{
  font-size: 18px;
}
h5{
  font-size: 14px;
}
.container {
  margin: auto;
  padding: 10px;
}
.member {
  padding: 10px;
  text-align: center;
}
.memberimg:hover {
  transition: 0.5s ease;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}
.textname {
  color: #000;
  margin-top: 3%;
  text-shadow: 2px 2px 5px gray;
}
img {
  width: 200px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


  

</style>
